body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.renglonTabla {
  cursor: pointer;
  font-size: 11px;
  }
  
  .numeroCotExterna {
    text-align: center;
    font-size: 12px;
    color: #ccc;
    cursor: pointer;
  }

  .renglonTabla:hover {
    color: #ca2228
  }
  
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}

:root {
  --color-grisOscuro: #666;
  /* --color-naranja: #ffa500; */
  --color-naranja: #ad7100;
  --color-azul: #001149;
}

/*  */

.logo {
  height: 40px;
}

.input {
  border-radius: 5px;
}
.ant-layout-sider-trigger {
  background-color: #fff !important;
  color: #1890ff !important;
  font-weight: bold !important;
}

.botonCard {
  color: var(--color-naranja);
  margin-top: 2px;
  border-radius: 5px;
  font-weight: 600;
}

.botonCard:hover {
  color: var(--color-naranja);
}

.card__titulo {
  margin-left: 15px;
  margin-bottom: 5px;
  color: var(--color-naranja);
  font-weight: 500;
  font-size: 20px;
}
.card__titulo_container {
}

.card__accion {
  text-align: right;
  padding-bottom: 5px;
  padding-right: 15px;
}
.card__contenido {
  width: 100%;
  padding: 10px;
  background-color: #fff;
  box-shadow: 1px 1px 5px #ddd;
  border-radius: 5px;
}

.inputLabel {
  color: var(--color-grisOscuro);
  margin-left: 5px;
}

.botonSubmit {
  color: #fff;
  background-color: var(--color-naranja);
  border: 1px solid var(--color-naranja) !important;
  border-radius: 5px;
}

.botonSubmit:hover {
  color: var(--color-naranja);
  background-color: #fff;
  border: 1px solid var(--color-naranja) !important;
}

.botonSubmit:disabled {
  border: 0px solid var(--color-naranja) !important;
}

.eyeIcon {
  color: var(--color-naranja);
  cursor: pointer;
}
.eyeIcon:hover {
  opacity: 0.5;
}

.tituloGrid {
  text-align: center;
  font-weight: 600;
}

.labelGrid {
  text-align: right;
  font-weight: 600;
}

.imgPosicion {
  height: 160px;
  margin-top: 25px;
}

.textoNaranja {
  font-weight: 600;
  color: var(--color-naranja);
}

.renglonArticulo:hover {
  background-color: #f1f1f1;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin: 5px 0;
}

.rowItem {
  padding: 15px 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  cursor: pointer;

  background: url(/src/assets/chevron-right.png) no-repeat;
  background-size: 20px 20px;
  background-position: right 10px bottom 15px;
  border-radius: 5px;
  margin: 0px 0;
}

.rowItem:hover,
.rowItemElemento:hover {
  background-color: #fcfcfc;
}

.selectedItem,
.selectedItem:hover {
  background-color: #f1f1f1;
}

.rowItemElemento {
  padding: 15px 15px;
  border-bottom: 1px solid #f1f1f1;
  color: #666;
  border-radius: 5px;
}

.rowItem:last-of-type,
.rowItemElemento:last-of-type {
  border-bottom: 0px solid #f1f1f1;
}

.escojaCategoria {
  text-align: center;
  padding: 50px 0;
  color: #666;
}

.ver {
  color: orange;
  font-size: 12px;
  margin-left: 25px;
}

.textoClick {
 padding-left: 5px;
 padding-right: 5px;
 color: var(--color-naranja);
 opacity: .5;
 cursor: pointer;
}

.textoClick:hover {
  opacity: 1;

}

.IconCheck {
  font-size: 25px;
  color: #666;
}

.IconCheck:hover {
  color: var(--color-naranja);
}

.label {
  font-weight: 700;
  margin-left: 5px;
  color: var(--color-azul);
}
.valor {
  padding: 2px 5px;
  background-color: #f1f1f1;
  border-radius: 5px;
  min-height: 25px;
}

.fileItem {
  cursor: pointer;
}

.fileItem:hover {
  background-color: #f1f1f1;
  color: var(--color-naranja);
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus {
  -webkit-box-shadow: 0 0 0px 1000px #ffffff inset !important;
}

.tituloLogin {
  text-align: center;

  color: #f1f1f1;
  font-weight: 500;
  font-size: 20px;
}

.full {
  height: 100vh;
  background-color: red;
  padding-top: 200px;
  background: url(backlogin.png);
  background-repeat: no-repeat;
  background-size: auto;
}

.loginCuadro {
  width: 350px;
  background-color: rgba(7, 60, 85, 0.5);
  padding: 20px 50px;
  border-radius: 50px;
  margin-left: auto;
  margin-right: auto;
}

.historialVentana {
  font-size: 11px;
  overflow-x: hidden;
  max-height: 270px;
  overflow-y: scroll;
}

.pill {
  padding: 1px;
  border-radius: 10px;
  width: 100px;
  text-align: center;
  float: right;
  font-weight: 600;
}

.capturando {
  background-color: #f1f1f1;
  color: #999;
}
.cotizacion {
  background-color: pink;
  color: #fff;
}

.autorizado {
  background-color: green;
  color: #fff;
}

.produccion {
  background-color: yellow;
  color: green;
}

.cerrado {
  background-color: blue;
  color: #fff;
}
.error {
  color: red;
}


.dele {
  text-align: center;
  max-width: 180px;
  padding: 3px 5px;
  font-weight: 600;
  font-size: 12px;
  cursor: pointer;

}
.botonEliminar {
  color: #ccc;
}

.botonEliminarActivo {
  color: #ff1212;
}

.botonEliminar:hover,.botonEliminarActivo {
  background-color: #f1f1f1;

} 

.inputLimpio {
  width: 75px;
  border: 0;
}

.inputLimpio:focus {
  outline: none !important;
  border-bottom: 1px solid #ccc;
}

.tituloRow {
    font-weight: "bold";
    text-align: "center";
    color: "orange";
    border-bottom: "1px solid #ccc";
    margin-bottom: 10px;

}

.itemRow {
  padding: 3px 0;
}

.titulo1 {
  text-align: center; 
  font-weight: 600; 
  color: #ca2228;
  border-bottom: solid 1px #ccc;

}